import rest from "@/services/shared/RestApi";

class CreateRecordAction {

    request(id, data) {
        return rest.post(this.getUrl(id, data.employeeId, data.recordId), data)
    }

    getUrl(id, employeeId, recordId) {
        return `/api/v1/account/${id}/employee/${employeeId}/record/${recordId}`
    }
}

export default new CreateRecordAction();
