import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Login from '@/pages/Login.vue'
import Dashboard from '@/pages/Dasboard.vue'
import EmployeeList from '@/pages/EmployeeList.vue'
import EmployeeNew from "@/pages/EmployeeNew"
import EmployeeRecord from "@/pages/EmployeeRecord"
import AttendanceRecords from '@/pages/AttendanceRecords'
import Skeletor from "@/pages/Skeletor"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', name: 'Login', component: Login, meta: { title: 'Login' } },
        { path: '/activation/account/:email/:token', name: 'AccountActivation', component: load('AccountActivation'), meta: { title: 'Account activation' } },
        { path: '/activation/employee/:email/:token', name: 'EmployeeActivation', component: load('EmployeeActivation'), meta: { title: 'Employee activation' } },
        { path: '/recovery/account/:email/:token', name: 'AccountPassRecovery', component: load('AccountPassRecovery'), meta: { title: 'Password recovery' } },
        { path: '/recovery/employee/:email/:token', name: 'EmployeePassRecovery', component: load('EmployeePassRecovery'), meta: { title: 'Password recovery' } },
        { path: '/password/account/', name: 'ResetAccountPassword', component: load('ResetAccountPassword'), meta: { title: 'Forgot Your Password?' } },
        { path: '/password/employee', name: 'ResetEmployeePassword', component: load('ResetEmployeePassword'), meta: { title: 'Forgot Your Password?' } },
        { path: '/panel', name: 'Main', component: Skeletor, meta: { requiresAuth: true }, children: [
            { path: '/', component: Dashboard, meta: { title: 'Dashboard' }  },
            { path: 'dashboard', name: 'Dashboard', component: Dashboard, meta: { title: 'Dashboard' } },
            { path: 'employee/new', name: 'EmployeeNew', component: EmployeeNew, meta: { title: 'New employee' } },
            { path: 'employee/:employeeId/details', name: 'EmployeeDetails', component: load('EmployeeDetails'), props: true, meta: { title: 'Employee details' }},
            { path: 'employee/:employeeId/records', name: 'EmployeeRecord', component: EmployeeRecord, props: true, meta: { title: 'Employee attendance' }},
            { path: 'employee/:employeeId/records/:recordId', name: 'EmployeeRecordDay', component: load('EmployeeRecordDay'), props: true, meta: { title: 'Employee attendance day' }},
            { path: 'employee/list', name: 'EmployeeList', component: EmployeeList, meta: { title: 'Employee list' } },
            { path: 'record', name: 'Record', component: AttendanceRecords, meta: { title: 'Records' } },
            { path: 'settings', name: 'Settings', component: load('AccountSettings'), meta: { title: 'Settings' } },
            { path: 'support', name: 'Support', component: load('Support'), meta: { title: 'Support' } },
            { path: '*', component: load('Error404'), meta: { title: 'Not found' } }
            ],
        },
        { path: '*', component: load('Error404'), meta: { title: 'Not found' } },
    ]
})

function load (component) {
    // Lazy loading - '@' is aliased to src/
    return () => import(`@/pages/${component}.vue`)
}

router.beforeEach((to, from, next) => {
    const dembora = process.env.VUE_APP_TITLE
    document.title = `${to.meta.title || dembora} | ${dembora}`
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/isLoggedIn']) {
            next()
            return
        }
        next('/')
    } else {
        next()
    }
})

export default router;
