import rest from "@/services/shared/RestApi"

class DropOutAccount {

    request(id) {
        return rest.delete(this.getUrl(id))
    }

    getUrl(id) {
        return `/api/v1/account/${id}`
    }
}

export default new DropOutAccount();
