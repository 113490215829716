export class Attendance {
    static type = {
        IN: 'IN',
        PAUSE: 'PAUSE',
        RESUME: 'RESUME',
        OUT: 'OUT',
    }
}

export const EMPTY_IMAGE_PROFILE = require("@/assets/images/empty-profile.png")
