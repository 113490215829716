import rest from "@/services/shared/RestApi"

class UpdateAccount {

    request(id, data) {
        return rest.patch(this.getUrl(id), data)
    }

    getUrl(id) {
        return `/api/v1/account/${id}`
    }
}

export default new UpdateAccount()
