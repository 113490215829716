import rest from "@/services/shared/RestApi";

class DeleteEmployee {

    request(id, employeeId) {
        return rest.delete(this.getUrl(id, employeeId))
    }

    getUrl(id, employeeId) {
        return `/api/v1/account/${id}/employee/${employeeId}`
    }
}

export default new DeleteEmployee();
