<template>
    <div class="mdl-grid">
        <header class="header-grey add-edit-employee-header">
            <div class="header-grey-row">
                <span class="wrapper-title">
                        <span class="name" v-if="name">{{ name }}</span>
                        <span class="material-icons">read_more</span> 
                        <span class="title">{{ title }} </span>
                </span>
            </div>
        </header>
    </div>
</template>

<style scope lang="scss">
.wrapper-title {
    display: flex;
    align-items: center;
    margin: 0px;
    position: relative;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 20px;
    line-height: 1;
    letter-spacing: .02em;
    font-weight: 400;
    box-sizing: border-box;

    .name {
    font-size: 1em;
    color: #000;
    }

    .material-icons {
        padding: 0px 10px;
    }

    .title {
    font-size: 1em;
    color: #2196f3;
    }
}
</style>

<script>
    export default {
        name: "SectionTitle",
        props: {
            title: {
                type: String,
                required: true
            },
            name: {
                type: String,
                default: ''
            },
        },
    }
</script>
