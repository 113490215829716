import router from '@/router'
import AccountLogin from '@/services/auth/AccountLogin'
import ActivationAccount from "@/services/auth/ActivationAccount"
import ActivationEmployee from "@/services/auth/ActivationEmployee"
import RecoveryPassAccount from "@/services/auth/RecoveryPassAccount"
import RecoveryPassEmployee from "@/services/auth/RecoveryPassEmployee"
import ResetPasswordAccount from "@/services/auth/ResetPasswordAccount"
import ResetPasswordEmployee from "@/services/auth/ResetPasswordEmployee"
import Session from '@/services/auth/Session'
import {Account} from "@/domain/Account"

export default {
    namespaced: true,
    state: {
        session : Session.get().session || '',
        requesting: false,
    },
    actions: {
        async login({commit}, user) {
            try {
                commit('setRequesting', true)
                const {User} = await AccountLogin.request(user)
                let time = new Date().toLocaleString(),
                    account = new Account(User.id, User.name, User.logo, User.email, User.billing, User.website, User.createdAt, User.plan, User.settings.lang)
                Session.create(account, time);
                commit('setSession', time)
                commit('account/setAccount', account, {root: true})
            } catch (error) {
                if (error.message.match('rest'))
                    throw new Error(error.message)
                throw new Error('auth.incorrect-login')
            } finally {
                commit('setRequesting', false)
            }
        },
        async logout({commit}) {
            Session.remove()
            commit('setSession', Session.get().session)
            commit('account/setAccount', Session.get().account, {root: true})
            await router.push( {name: "Login" })
        },
        async activateEmployee({commit}, activation) {
            try {
                commit('setRequesting', true)
                await ActivationEmployee.request(activation)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async activateAccount({commit}, activation) {
            try {
                commit('setRequesting', true)
                await ActivationAccount.request(activation)
                //await router.push( {name: "Login" })
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async recoveryAccount({commit}, data) {
            try {
                commit('setRequesting', true)
                await RecoveryPassAccount.request(data)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async recoveryEmployee({commit}, data) {
            try {
                commit('setRequesting', true)
                await RecoveryPassEmployee.request(data)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async resetAccount({commit}, email) {
            try {
                commit('setRequesting', true)
                await ResetPasswordAccount.request(email)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async resetEmployee({commit}, email) {
            try {
                commit('setRequesting', true)
                await ResetPasswordEmployee.request(email)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
    },
    getters: {
        isLoggedIn: state => !!state.session,
    },
    mutations: {
        setSession(state, session) {
            state.session = session;
        },
        setRequesting(state, isRequesting) {
            state.requesting = isRequesting
        }
    }
}
