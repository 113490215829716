import en from '@/locales/en.json'
import es from '@/locales/es.json'
import Vue from 'vue'
import Config from '@/services/config/Configuration'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
    locale: Config.get().locate,
    fallbackLocale: Config.get().locate,
    messages: {
        'en': en,
        'es': es
    }
})
