<template>
    <div class="page-content">
        <div v-if="employee">
            <section-title :title="title" :name="fullName"/>
        </div>

        <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                <router-link class="mdl-navigation__link back-list" :to="{ name: 'EmployeeList' }">
                    <i class="material-icons">keyboard_arrow_left</i> {{ $t('shared.back') }}
                </router-link>
            </div>
        </div>

        <div class="mdl-grid">
            <div id="employee-records">
                <employee-record-item
                        v-for="record in records"
                        :key="record.id"
                        :record="record"
                ></employee-record-item>
            </div>
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import SectionTitle from "@/components/shared/SectionTitle"
    import VueElementLoading from 'vue-element-loading'
    import EmployeeRecordItem from "@/components/record/EmployeeRecordItem"

    export default {
        name: "EmployeeRecord",
        props: ['employeeId'],
        data() {
            return {
                title: this.$i18n.t('record-employee.title')
            }
        },
        async created() {
            await this.getEmployeeRecord({employeeId: this.employeeId, start: '', finish: ''})
            if (this.employee.id === undefined) {
                await this.goToEmployeeList()
            }
        },
        computed: {
            employee() {
                return this.$store.getters['employee/getEmployeeById'](this.employeeId)
            },
            ...mapState({
                records: state => state.record.employeeRecords,
                show: state => state.record.requesting
            }),
            fullName() {
                return `${this.employee.name} ${this.employee.surname}`
            }
        },
        methods: {
            ...mapActions('record', ['getEmployeeRecord']),
            async goToEmployeeList() {
                await this.$router.push({name: 'EmployeeList'})
            },
        },
        components: {
            SectionTitle,
            EmployeeRecordItem,
            VueElementLoading
        }
    }
</script>
