export class ErrorApiCode {
    type = {
        500: 'rest.domain-error',
        601: 'rest.incorrect-credentials',
        602: 'rest.no-session',
        603: 'rest.employee-rights',
        604: 'rest.account-rights',
        605: 'rest.admin-rights',
        606: 'rest.token-no-valid',
        701: 'rest.invalid-argument',
        702: 'rest.unique-key-repeated',
        703: 'rest.missing-argument',
        801: 'rest.object-not-found',
        802: 'rest.cannot-delete',
        901: 'rest.cannot-create-biometric',
        902: 'rest.not-face-detected',
        903: 'rest.employee-not-found',
        904: 'rest.cannot-delete-biometric',
        905: 'rest.low-verification-score',
        907: 'rest.too-many-faces',
        908: 'rest.biometric-unknown-error',
        909: 'rest.biometric-down',
    }

    check(code) {
        return this.type[code] || 'rest.unknown-code'
    }
}
