import rest from "@/services/shared/RestApi";

class SendActivateNotification {

    request(id, employeeId) {
        return rest.post(this.getUrl(id, employeeId))
    }

    getUrl(id, employeeId) {
        return `/api/v1/account/${id}/employee/${employeeId}/activate`
    }
}

export default new SendActivateNotification();
