import rest from "@/services/shared/RestApi";

class UpdateRecordAction {

    request(id, data) {
        return rest.patch(this.getUrl(id, data.employeeId, data.recordId, data.actionId), data)
    }

    getUrl(id, employeeId, recordId, actionId) {
        return `/api/v1/account/${id}/employee/${employeeId}/record/${recordId}/action/${actionId}`
    }
}

export default new UpdateRecordAction();
