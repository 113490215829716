import Vue from "vue"
import Create from "@/services/record/CreateRecordAction"
import Fetch from "@/services/record/FetchEmployeeRecord"
import Update from "@/services/record/UpdateRecordAction"
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        attendanceRecordFilter: { employeeId: '', start: '', finish: ''},
        attendanceRecords: [],
        employeeRecords: [],
        requesting: false,
    },
    actions: {
        async getEmployeeRecord({commit, rootState}, filter) {
            try {
                commit('setRequesting', true)
                commit('setEmployeeRecord', [])
                let {Records} = await Fetch.request(rootState.account.account.id, filter)
                commit('setEmployeeRecord', Records)
            } catch (error) {
                Vue.$toast.error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async getAttendanceRecord({commit, rootGetters, rootState}, filter) {
            try {
                commit('setRequesting', true)
                const {Records} = await Fetch.request(rootState.account.account.id, filter)
                Records.forEach(record => {
                    record['employee'] = rootGetters["employee/getEmployeeById"](filter.employeeId)
                })
                commit('setEmployeeRawRecord', Records)
                commit('setEmployeeRawRecordFilter', filter)
            } catch (error) {
                Vue.$toast.error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async createAttendanceTime({commit, rootState}, data) {
            try {
                commit('setRequesting', true)
                const {ActionId} = await Create.request(rootState.account.account.id, data)

                commit('addRecordAction', {
                    actionId: ActionId,
                    id: data.recordId,
                    employeeId: data.employeeId,
                    actionType: data.actionType,
                    placeMark: '',
                    createdAt: data.createdAt,
                    image: '',
                    score: 0,
                    geo: ''
                })
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async updateAttendanceTime({commit, rootState}, data) {
            try {
                commit('setRequesting', true)
                const {RecordUpdated} = await Update.request(rootState.account.account.id, data)
                commit('updateRecordAction', RecordUpdated)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        }
    },
    getters: {
        getRecordById: (state) => (recordId) => {
            return state.employeeRecords[recordId]
        },
    },
    mutations: {
        setEmployeeRecord(state, record) {
            state.employeeRecords = _.groupBy(record, (record) => record.id);
        },
        addRecordAction(state, action) {
            const record = state.employeeRecords[action.id]
            record.unshift(action)
        },
        updateRecordAction(state, action) {
            const record = state.employeeRecords[action.id]
            record.filter(a => {
                if (a.actionId === action.actionId) {
                    a.createdAt = action.createdAt
                }
            })
        },
        setEmployeeRawRecord(state, record) {
            state.attendanceRecords = record;
        },
        setEmployeeRawRecordFilter(state, filter) {
            state.attendanceRecordFilter = filter
        },
        setRequesting(state, isRequesting) {
            state.requesting = isRequesting
        }
    }
}
