import rest from "@/services/shared/RestApi"

class RecoveryPassAccount {

    request(data) {
        return rest.post(this.getUrl(), data)
    }

    getUrl() {
        return `/api/v1/recovery/account`
    }
}

export default new RecoveryPassAccount();
