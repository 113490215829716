<template>
    <span class="current-time">{{ date | moment('LL') }}</span>
</template>

<style scope lang="scss">
    .current-time {
        font-size: 1em;
        color: #000;
    }
</style>

<script>
    export default {
        name: 'WidgetCurrentTime',
        data: function() {
            return {
                date: new Date()
            }
        }
    }
</script>
