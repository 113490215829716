// Web to Case function
// more info:
// https://gist.github.com/mhamzas/f2c445ce18bb71b5fce193eddd7ed007
// https://www.adminbooster.com/blog/hacking_web2lead_web2case
export function webToCase(fields) {
    let customHiddenIframeName = 'JLA_API';
    if (!document.getElementById(customHiddenIframeName)) {
        let theiFrame = document.createElement("iframe");
        theiFrame.id = customHiddenIframeName;
        theiFrame.name = customHiddenIframeName;
        theiFrame.src = 'about:blank';
        theiFrame.style.display = 'none';
        document.body.appendChild(theiFrame);
    }
    fields['retURL'] = 'http://127.0.0.1';//dummy URL
    let form = document.createElement("form");
    form.method = "POST";
    form.action = "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
    form.setAttribute("target", customHiddenIframeName);
    for (let fieldName in fields) {
        let theInput = document.createElement("input");
        theInput.name = fieldName;
        theInput.value = fields[fieldName];
        theInput.setAttribute("type", "hidden");
        form.appendChild(theInput);
    }
    document.body.appendChild(form);
    form.submit();
}
