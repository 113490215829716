import rest from "@/services/shared/RestApi";

class UpdateEmployee {

    request(id, data) {
        return rest.patch(this.getUrl(id, data.id), data)
    }

    getUrl(id, employeeId) {
        return `/api/v1/account/${id}/employee/${employeeId}`
    }
}

export default new UpdateEmployee();
