import rest from '@/services/shared/RestApi'

class FetchEmployees {

    request(id) {
        return rest.get(this.getUrl(id))
    }

    getUrl(id) {
        return `/api/v1/account/${id}/employee`
    }
}

export default new FetchEmployees()
