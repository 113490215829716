import store from '@/store'
import axios from 'axios'
import qs from 'qs'
import {ErrorApiCode} from "@/services/shared/ErrorApiCode"

const rest = axios.create()
rest.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
rest.defaults.timeout = 5000

class RestApi {

    async get(path) {
        try {
            const response = await rest.get(path, { withCredentials: true })
            return response.data
        } catch (error) {
            await this.verify(error)
        }
    }

    async post(url, data) {

        try {
            const response = await rest({
                    method: 'post',
                    url: url,
                    data: qs.stringify(data),
                    withCredentials: true,
                    headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
                }
            )
            return response.data
        } catch (error) {
            await this.verify(error)
        }
    }

    async patch(url, data) {

        try {
            const response = await rest({
                    method: 'patch',
                    url: url,
                    data: qs.stringify(data),
                    withCredentials: true,
                    headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
                }
            )
            return response.data
        } catch (error) {
            await this.verify(error)
        }
    }

    async delete(path) {
        try {
            await rest.delete(path, { withCredentials: true })
        } catch (error) {
            await this.verify(error)
        }
    }

    async verify(error) {
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            if (error.response.status === 401) {
                //mapActions('auth', ['logout'])
                await store.dispatch("auth/logout", null, {root: true})
                throw new Error('rest.no-session')
            }
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            //console.log(error.request)
            throw new Error('rest.domain-error')
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message)
        }
        let code = new ErrorApiCode();
        throw new Error(code.check(error.response.data.Error.Code))
    }
}

export default new RestApi();
