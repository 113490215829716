import Vue from 'vue'
import i18n from "@/i18n"
import Config from "@/services/config/Configuration"
import * as locates from "vuejs-datepicker/src/locale";

export default {
    namespaced: true,
    state: {
        locate : Config.get().locate,
    },
    actions: {
        changeLang({commit}, locate) {
            i18n.locale = locate
            i18n.fallbackLocale = locate
            Vue.moment.locale(locate)
            Config.update(locate)
            commit('setLocate', locate)
        },
        lang({commit}) {
            const locate = localStorage.getItem('dembora.locate')
            i18n.locale = locate
            i18n.fallbackLocale = locate
            Vue.moment.locale(locate)
            commit('setLocate', locate)
        }
    },
    getters: {
        pickerLang: (state) => {
            return locates[state.locate]
        }
    },
    mutations: {
        setLocate(state, locate) {
            state.locate = locate
        },
    }
}
