import rest from "@/services/shared/RestApi";

class CreateEmployee {

    request(id, data) {
        return rest.post(this.getUrl(id), data)
    }

    getUrl(id) {
        return `/api/v1/account/${id}/employee`
    }
}

export default new CreateEmployee();
