<template>
    <div id="wrapper-table">
        <table id="table-employees" class="mdl-data-table mdl-js-data-table mdl-shadow--2dp">
            <thead>
                <record-action-table-header v-if="records.length > 0"></record-action-table-header>
            </thead>
            <tbody>
                <record-action-table-row-item
                    v-for="record in records"
                    :key="record.actionId"
                    :id="record.actionId"
                    :name="record.employee.name"
                    :surname="record.employee.surname"
                    :action="record.actionType"
                    :date="record.createdAt"
                    :place="record.placeMark"
                    :image="record.image"
                    :score="record.score"
                    :geo="record.geo"
                ></record-action-table-row-item>
            </tbody>
        </table>
    </div>
</template>

<script>

    import RecordActionTableHeader from "@/components/record/RecordActionTableHeader";
    import RecordActionTableRowItem from "@/components/record/RecordActionTableRowItem";

    export default {
        name: "RecordActionTable",
        props: {
            records: Array,
        },
        components: { RecordActionTableRowItem, RecordActionTableHeader },
    }

</script>
