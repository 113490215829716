<template>
    <div id="attendance-records" class="page-content">
        <div class="mdl-grid">
            <header class="header-grey">

                <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-filter">

                    <form class="filter" @submit.prevent="handleSearch">

                        <div class="date">
                            <label>{{ $t('list-employee-record.employee') }}:</label><br>

                            <md-field class="md-select-overwrite">
                            <md-select class="select-person" v-model="filter.employeeId" title="Select employee">
                                <md-option disabled value="">{{ $t('list-employee-record.select-employee') }}</md-option>
                                <md-option v-for="employee in getEmployees"
                                           :key="employee.id"
                                           :value="employee.id">
                                    {{ employee.email }}
                                </md-option>
                            </md-select>
                            </md-field>
                        </div>

                        <div class="date">
                            <label>{{ $t('list-employee-record.start-date') }}:</label><br>
                            <datepicker v-model="filter.start"
                                        :language="pickerLang"
                                        placeholder=""
                                        clear-button
                                        name="filter-start">
                            </datepicker>
                        </div>

                        <div class="date">
                            <label>{{ $t('list-employee-record.end-date') }}:</label><br>
                            <datepicker v-model="filter.finish"
                                        :language="pickerLang"
                                        placeholder=""
                                        clear-button
                                        name="filter-finish">
                            </datepicker>
                        </div>
                        <div class="date">
                            <br>
                            <button class="btn-filter" type="submit">{{ $t('list-employee-record.search') }}</button>
                        </div>
                    </form>
                    <div class="date export">
                        <br>
                        <json-excel
                                class="button wrapper-filter filter"
                                type="xls"
                                :data="records"
                                :fields="fields">
                            <span id="export" class="mdl-chip mdl-chip--deletable">
                                <span class="mdl-chip__text">{{ $t('list-employee-record.export') }}</span>
                                <button type="button" class="mdl-chip__action btn-export"><i class="material-icons">import_export</i></button>
                            </span>
                            <div class="mdl-tooltip" data-mdl-for="export">
                                 <strong>{{ $t('list-employee-record.export-tooltip') }}</strong>
                            </div>
                        </json-excel>
                    </div>

                </div>

            </header>

            <div class="wrapper-pagination" v-if="records">
                    <pagination :items="records" @changePage="onChangePage"></pagination>
            </div>

        </div>
        <div class="mdl-grid">
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
            <record-action-table :records="pageOfItems"/>
        </div>
    </div>
</template>

<style scope lang="scss">
    .wrapper-filter {
        display: flex;
    }

    .vdp-datepicker * {
        box-sizing: border-box;
        border: none;
    }

    .page-item.active .page-link {
        background-color: #efefef;
        border-color: #efefef;
    }

    .date.export {
        margin-left: auto;
    }
</style>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import VueElementLoading from 'vue-element-loading'
    import Pagination from '@/components/shared/Pagination';
    import JsonExcel from 'vue-json-excel'
    import RecordActionTable from '@/components/record/RecordActionTable'
    import Datepicker from 'vuejs-datepicker'

    export default {
        name: 'AttendanceRecords',
        data() {
            return {
                pageOfItems: [],
                fields: {
                    'Name': 'employee.name',
                    'Surname': 'employee.surname',
                    'Attendance': 'actionType',
                    'City': 'placeMark',
                    'Date': 'createdAt'
                },
            }
        },
        computed: {
            ...mapState({
                records: state => state.record.attendanceRecords,
                filter: state => state.record.attendanceRecordFilter,
                show: state => state.record.requesting,
                account: state => state.account.account,
            }),
            ...mapGetters('employee', ['getEmployees']),
            ...mapGetters('config', ['pickerLang'])
        },
        methods: {
            ...mapActions('record', ['getAttendanceRecord']),
            async handleSearch() {
                if (this.filter.employeeId === '') {
                    return this.$toast.error(this.$i18n.t('list-employee-record.select-employee'))
                }
                try {
                    this.filter.start = this.filter.start ?? ''
                    this.filter.finish = this.filter.finish ?? ''
                    await this.getAttendanceRecord(this.filter)
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
            onChangePage(pageOfItems) {
                this.pageOfItems = pageOfItems;
            }
        },
        components: {
            RecordActionTable,
            VueElementLoading,
            Pagination,
            Datepicker,
            JsonExcel
        }
    }

</script>
