import Vue from 'vue'
import Vuex from 'vuex'
import accountModule from '@/store/modules/account'
import authModule from '@/store/modules/auth'
import configModule from "@/store/modules/config"
import employeeModule from '@/store/modules/employee'
import recordModule from '@/store/modules/record'
import salesforce from "@/store/modules/salesforce"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        account: accountModule,
        auth: authModule,
        config: configModule,
        employee: employeeModule,
        record: recordModule,
        salesforce: salesforce
    }
});
