<template>

    <div class="page-content">
        <div class="mdl-grid">
            <header class="header-grey">

                <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-filter">
                    <form class="filter" @submit.prevent="handleSearch">

                        <div class="date">
                            <label for="filter-keyword">{{ $t('list-employees.search')}}:</label><br>
                            <input type="text" id="filter-keyword" v-model="filter.keyword" name="filter-keyword" :placeholder="searchPlaceholder" />
                        </div>

                        <div class="date">
                            <label>{{ $t('list-employees.start-date')}}:</label><br>
                            <datepicker v-model="filter.start"
                                        :language="pickerLang"
                                        placeholder=""
                                        clear-button
                                        name="filter-start">
                            </datepicker>
                        </div>

                        <div class="date">
                            <label>{{ $t('list-employees.end-date')}}:</label><br>
                            <datepicker v-model="filter.finish"
                                        :language="pickerLang"
                                        placeholder=""
                                        clear-button
                                        name="filter-finish">
                            </datepicker>
                        </div>
                        <div class="date">
                            <br>
                            <button class="btn-filter" type="submit">{{ $t('list-employees.search')}}</button>
                        </div>
                    </form>

                </div>
            </header>

            <div class="wrapper-pagination" v-if="employees">
                <pagination :items="employees" @changePage="onChangePage"></pagination>
            </div>
            
        </div>
        <div class="mdl-grid">
            <employee-table :employees="pageOfItems" />
        </div>

        <router-link id="floating-add-employee" :to="{ name: 'EmployeeNew' }" exact>
            <button  class="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect mdl-button--green">
                <i class="material-icons">add</i>
            </button>
        </router-link>
        

    </div>
</template>

<style scope lang="scss">
#floating-add-employee {
    position: fixed;
    bottom: 22px;
    right: 43px;
    z-index: 9999;
}
.mdl-button--fab.mdl-button--green {
    background: #019587;
    color: #fff;
    &:hover {
        background: #019587;
        color: #fff;
    }
}
</style>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import EmployeeTable from '@/components/employee/EmployeeTable'
    import Pagination from '@/components/shared/Pagination';
    import Datepicker from 'vuejs-datepicker'

    export default {
        name: 'EmployeeList',
        created() {
            this.filterEmployees(this.filter)
            this.searchPlaceholder = this.$i18n.t('list-employees.search') + '...'
        },
        data() {
            return {
                pageOfItems: [],
                searchPlaceholder: ''
            }
        },
        computed: {
            ...mapState({
                account  : state => state.account.account,
                employees: state => state.employee.listEmployeesFiltered,
                filter   : state => state.employee.listEmployeesFilter
            }),
            ...mapGetters('config', ['pickerLang'])
        },
        methods: {
            ...mapActions('employee', ['filterEmployees']),
            handleSearch() {
                this.filterEmployees(this.filter)
            },
            onChangePage(pageOfItems) {
                this.pageOfItems = pageOfItems;
            }
        },
        components: {
            EmployeeTable,
            Pagination,
            Datepicker
        }
    }

</script>
