<template>
    <div class="mdl-layout__container">
        <div class="mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
            <top-bar />
            <drawer />
            <main id="container-data" class="mdl-layout__content">
                <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
                <transition name="view">
                    <router-view />
                </transition>
            </main>
        </div>
    </div>
</template>

<style scope lang="scss">
</style>

<script>
    import {mapActions} from 'vuex'
    import Drawer from '../components/skeletor/Drawer'
    import TopBar from "@/components/skeletor/TopBar"
    import VueElementLoading from 'vue-element-loading'

    export default {
        name: 'Skeletor',
        data() {
            return {
                show: true,
            }
        },
        async created() {
            await this.getEmployees()
            this.show = false
        },
        methods: {
            ...mapActions('employee', ['getEmployees'])
        },
        components: {
            TopBar,
            Drawer,
            VueElementLoading
        }
    }
</script>

<style scoped>
    .view-enter-active, .view-enter-leave {
        transition: opacity 0.3s ease-in-out, transform 0.3s ease;
    }
    .view-enter-active {
        transition-delay: 0.1s;
    }
    .view-enter, .view-leave-to {
        opacity: 0;
    }
    .view-enter-to, .view-leave {
        opacity: 1;
    }

</style>
