<template>
    <div>
        <div class="mdl-grid">
            <section class="dashboard-actions-blocks">
                <div class="state">
                    <p class="state-title">IN</p>
                    <div class="users">
                        <ProfileAttendance
                            v-for="attendance in employeesIn"
                            :key="attendance.id"
                            :attendance="attendance"
                        ></ProfileAttendance>
                    </div>
                </div>
            </section>
        </div>

        <div class="mdl-grid">
            <section class="dashboard-actions-blocks">
                <div class="state">
                    <p class="state-title">PAUSE</p>
                    <div class="users" id="dashboard-pause">
                        <profile-attendance
                                v-for="attendance in employeesPause"
                                :key="attendance.id"
                                :attendance="attendance"
                        ></profile-attendance>
                    </div>
                </div>
            </section>
        </div>

        <div class="mdl-grid">
            <section class="dashboard-actions-blocks">
                <div class="state">
                    <p class="state-title">OUT</p>
                    <div class="users" id="dashboard-out">
                        <profile-attendance
                            v-for="attendance in employeesOut"
                            :key="attendance.id"
                            :attendance="attendance"
                        ></profile-attendance>
                    </div>
                </div>
            </section>
        </div>

    </div>
</template>

<script>

    import ProfileAttendance from '@/components/dashboard/ProfileAttendance'

    export default {
        name: 'ProfileAttendanceList',
        props: {
            employeesIn: {
                type: Array,
            },
            employeesPause: {
                type: Array,
            },
            employeesOut: {
                type: Array
            }
        },
        components: { ProfileAttendance },
    }
</script>
