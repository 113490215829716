import Vue from 'vue'
import Select from "@/services/employee/FetchEmployee"
import Create from "@/services/employee/CreateEmployee"
import Remove from "@/services/employee/DeleteEmployee"
import Update from "@/services/employee/UpdateEmployee"
import Activation from "@/services/employee/SendActivateNotification"
import _ from 'lodash'
import {Attendance} from "@/services/shared/Attendance";

// Backend refresh domain events every 1 min
const REFRESH_GET_EMPLOYEES = 60000

export default {
    namespaced: true,
    state: {
        employees: [],
        lastGetEmployeesRequest: new Date('1978-09-08'),
        listEmployeesFiltered: [],
        listEmployeesFilter: {keyword: '', start: '', finish: ''},
        requesting: false,
        dashboardFilter: ''
    },
    actions: {
        async getEmployees({commit, state, rootState}) {
            try {
                const last = new Date() - state.lastGetEmployeesRequest
                if (last < REFRESH_GET_EMPLOYEES) return
                commit('setRequesting', true)
                commit('setLastGetEmployeeRequest')
                const {Employees} = await Select.request(rootState.account.account.id)
                commit('setEmployees', Employees)
            } catch (error) {
                Vue.$toast.error(error.message)
            }
            commit('setRequesting', false)
        },
        async addEmployee({commit, getters, rootState}, employee) {
            try {
                commit('setRequesting', true)
                const e = getters.getEmployees.filter(e => e.email.toLowerCase().match(employee.email.toLowerCase()))
                if(e.length !== 0) {
                    throw new Error('rest.already-employee')
                }
                const {EmployeeId} = await Create.request(rootState.account.account.id, employee)
                employee.id = EmployeeId
                employee.status = "OUT"
                employee.createdAt = new Date()
                commit('addEmployee', employee)
            } catch (error) {
                commit('setRequesting', false)
                throw new Error(error.message)
            }
            commit('setRequesting', false)
        },
        async updateEmployee({commit, rootState}, employee) {
            try {
                commit('setRequesting', true)
                const {EmployeeUpdated} = await Update.request(rootState.account.account.id, employee)
                commit('updateEmployee', EmployeeUpdated)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async deleteEmployee({commit, rootState}, employeeId) {
            try {
                commit('setRequesting', true)
                await Remove.request(rootState.account.account.id, employeeId)
                commit('deleteEmployee', employeeId)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        filterEmployees({commit, getters}, filter) {
            const start = new Date(filter.start ?? '')
            const finish = new Date(filter.finish ?? '')
            const filtered = getters.getEmployees.filter(employee => {
                if ((!isNaN(start.getTime()) && start.setHours(0,0,0,0) >= new Date(employee.createdAt)) ||
                    (!isNaN(finish.getTime()) && finish.setHours(23,59,59) <= new Date(employee.createdAt)))  {
                    return false
                }
                return !!(
                    employee.name.toLowerCase().match(filter.keyword.toLowerCase()) ||
                    employee.surname.toLowerCase().match(filter.keyword.toLowerCase()) ||
                    employee.code.toLowerCase().match(filter.keyword.toLowerCase()) ||
                    employee.email.toLowerCase().match(filter.keyword.toLowerCase())
                )
            })
            commit('setEmployeesFiltered', filtered)
            commit('setEmployeesFilter', filter)
        },
        updateFilterDashboard({commit}, filter){
            commit('updateFilterDashboard', filter)
        },
        async sendActivation({commit, rootState}, employeeId) {
            try {
                commit('setRequesting', true)
                await Activation.request(rootState.account.account.id, employeeId)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
    },
    getters: {
        getEmployeeById: (state) => (employeeId) => {
            return state.employees[employeeId]
        },
        getEmployees(state) {
            return _.sortBy(Object.values(state.employees), (employee) => employee.email)
        },
        getCountEmployees(state, getters) {
            return getters.getEmployees.length
        },
        employeesInStatus(state, getters) {
            return getters.getEmployees.filter(e => (e.status.includes(Attendance.type.IN) || e.status.includes(Attendance.type.RESUME)))
        },
        employeesOutStatus(state, getters) {
            return getters.getEmployees.filter(e => e.status.includes(Attendance.type.OUT || e.status.includes(state.dashboardFilter)))
        },
        employeesPauseStatus(state, getters) {
            return getters.getEmployees.filter(e => e.status.includes(Attendance.type.PAUSE))
        },
    },
    mutations: {
        setEmployees(state, employees) {
            state.employees = Object.assign({}, ...employees.map((x) => ({[x.id]: x})));
            state.listEmployeesFiltered = _.sortBy(Object.values(state.employees), ['name', 'surname'])
        },
        addEmployee(state, employee) {
            const employees = Object.assign({}, state.employees)
            employees[employee.id] = employee
            state.employees = employees
        },
        updateEmployee(state, employeeUpdate) {
            state.employees[employeeUpdate.id] = employeeUpdate
        },
        deleteEmployee(state, employeeId) {
            const employees = Object.assign({}, state.employees)
            delete employees[employeeId]
            state.employees = employees
        },
        setEmployeesFiltered(state, employeesFiltered) {
            state.listEmployeesFiltered = employeesFiltered
        },
        setEmployeesFilter(state, filter) {
            state.listEmployeesFilter = filter
        },
        setLastGetEmployeeRequest(state) {
            state.lastGetEmployeesRequest = new Date()
        },
        setRequesting(state, isRequesting) {
            state.requesting = isRequesting
        },
        updateFilterDashboard(state, filter) {
            state.dashboardFilter = filter
        }
    }
}
