<template>
    <div id="dashboard" class="page-content">
        <div class="mdl-grid">
            <header class="header-grey">
                <div class="header-grey-row">
                    <span class="mdl-layout__title wrapper-title">
                        <span class="pre">{{ $t('dashboard.date')}}: </span><widget-current-time/>
                    </span>

                    <span class="mdl-layout__title wrapper-number">
                        <span class="pre">
                            <span class="text">{{ $t('dashboard.number-employees')}}</span>
                            <span class="material-icons mdl-badge mdl-badge--overlap" :data-badge="getCountEmployees">account_box</span>
                        </span>
                    </span>
 
                </div>
                <!--
                <div class="mdl-layout__header-row">

                    <div class="date">
                        <label for="filter-keyword">{{ $t('list-employees.search')}}:</label><br>
                        <input type="text" id="filter-keyword" name="filter-keyword" v-model="employeeFilter" @change="handleFilter"/>
                    </div>

                </div>
                -->
            </header>
        </div>
        <div>
            <profile-attendance-list
                    :employees-in="employeesInStatus"
                    :employees-out="employeesOutStatus"
                    :employees-pause="employeesPauseStatus"
            ></profile-attendance-list>
        </div>
    </div>
</template>

<style scope lang="scss">

.filter {
    display: flex;
    flex-direction: row;

    .date {
        margin-right: 10px;
    }

    label {
        font-size: 0.8em;
        color: #949494;
        margin: 0;
        padding: 0;
    }
    input#filter-keyword, input {
        border: none;
        height: 31px;
    }
    .submit {
        display: flex;
        align-items: center;
        height: 31px;
        background-color: #2196f3;
        border: none;
        color: #fff;
        margin-top: 0;
        padding: 9px;
    }
}
</style>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import WidgetCurrentTime from '@/components/dashboard/WidgetCurrentTime'
    import ProfileAttendanceList from "@/components/dashboard/ProfileAttendanceList"

    export default {
        name: 'Dashboard',
        data() {
            return {
                refreshEmployeeTimer: '',
                employeeFilter: ''
            }
        },
        created() {
            this.getEmployees()
            this.updateFilterDashboard(this.employeeFilter)
            this.refreshEmployeeTimer = setInterval(()=> this.getEmployees(), 30000)
        },
        computed: {
            ...mapGetters('employee', ['employeesInStatus', 'employeesOutStatus', 'employeesPauseStatus', 'getCountEmployees']),
        },
        methods: {
            ...mapActions('employee', ['getEmployees', 'updateFilterDashboard']),
            cancelAutoUpdate() {
                clearInterval(this.refreshEmployeeTimer)
            },
            handleFilter() {
                this.updateFilterDashboard(this.employeeFilter)
            }
        },
        beforeDestroy () {
            this.cancelAutoUpdate()
            clearInterval(this.refreshEmployeeTimer)
        },
        components: {ProfileAttendanceList, WidgetCurrentTime}
    }

</script>
