<template>
    <div class="component">
        <div class="component-line">
            <div class="mask-image">
                <img class="image-grid" :src="image" alt="Matching image">
            </div>
            <p class="date">{{ time | moment('ll') }}</p>

            <div class="wrapper-hours">
                <p class="title">{{ $t('record-employee.work') }}</p>
                <span class="hours" :class="color">{{ total.work }}</span>
            </div>
            
            <div class="wrapper-breaks">
                <p class="title">{{ $t('record-employee.break') }}</p>
                <span class="breaks" :class="color">{{ total.break }}</span>
            </div>
            
            <div class="wrapper-link-record-day">
                <router-link class="link-record-day" :to="{
                                name: 'EmployeeRecordDay',
                                params: {
                                    employeeId: employeeId,
                                    recordId: recordId
                                }
                            }"
                            exact>
                    <span class="material-icons records">fact_check</span>
                </router-link>
                <p class="title">{{ $t('record-employee.details') }}</p>
            </div>
        </div>
    </div>
</template>

<style scope lang="scss">
</style>

<script>
    import {Attendance} from "@/services/shared/Attendance"
    import {StandardizeDateTime} from "@/services/shared/Utils"

    const HOUR = 3600
    const MINUTES = 60

    export default {
        name: "EmployeeRecordItem",
        props: {
            record: Array,
        },
        computed: {
            total() {
                if (this.record.length % 2 !== 0) {
                    return {
                        work: this.$i18n.t('record-employee.working'),
                        break: this.$i18n.t('record-employee.working')
                    }
                }
                let working = [], nonworking = []
                for (let i = 0; i < this.record.length - 1; i++) {
                    if (this.record[i + 1].actionType !== Attendance.type.PAUSE) {
                        working.push({finish: this.record[i], start: this.record[i + 1]})
                    } else {
                        nonworking.push({finish: this.record[i], start: this.record[i + 1]})
                    }
                }
                let worked = 0
                working.forEach((register) => {
                    worked += this.sum(register.start.createdAt, register.finish.createdAt)
                })
                let paused = 0
                nonworking.forEach((register) => {
                    paused += this.sum(register.start.createdAt, register.finish.createdAt)
                })
                return {
                    work: this.secondToHumanReadable(worked),
                    break: this.secondToHumanReadable(paused)
                }
            },
            image() {
                return this.record.find(a => a.actionType === Attendance.type.IN).image
            },
            time() {
                let lastRecord = this.record.length
                return StandardizeDateTime(this.record[lastRecord - 1].createdAt)
            },
            recordId() {
                return this.record[0].id
            },
            employeeId() {
                return this.record[0].employeeId
            },
            color() {
                if (this.total.break === this.$i18n.t('record-employee.working')) {
                    return 'issue'
                }
                return ''
            }
        },
        methods: {
            sum(start, end) {
                return Math.abs(new Date(end) - new Date(start)) / 1000
            },
            secondToHumanReadable(seconds) {
                let workingHours = parseInt(seconds / HOUR)
                let workingMinutes = parseInt((seconds - HOUR * workingHours) / MINUTES)
                return `${workingHours}h. ${workingMinutes}min.`
            }
        }
    }
</script>

