import rest from "@/services/shared/RestApi"

class ResetPasswordAccount {

    request(email) {
        return rest.post(this.getUrl(email))
    }

    getUrl(email) {
        return `/api/v1/reset/account/${email}`
    }
}

export default new ResetPasswordAccount();
