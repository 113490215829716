<template>
    <tr>
        <th id="photo" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.profile') }}</th>
        <th id="name" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.full-name') }}</th>
        <th id="action" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.action') }}</th>
        <th id="date" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.date') }}</th>
        <th id="time" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.time') }}</th>
        <th id="zone" class="mdl-data-table__cell--non-numeric">{{ $t('shared.timezone') }}</th>
        <th id="gps" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.placemark') }}</th>
    </tr>
</template>

<script>
    export default {
        name: "RecordActionTableHeader",
    }
</script>
