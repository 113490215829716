<template>
    <div id="add-employee" class="page-content">

        <section-title :title="title"/>

        <div class="mdl-grid">
            <form @submit.prevent="handleSubmit">

                <div class="mdl-grid">

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <div id="msg-profile" class="mdl-textfield mdl-js-textfield">
                            <div class="mask-edit">
                                <img :src="profile" class="profile-foto-edit" id="profile-employee"
                                     alt="Employee profile image">
                            </div>
                            <label for="upload-profile" class="add-image-profile">
                                <span class="material-icons">cloud_upload</span>
                                <span class="upload-text">{{ $t('add-employee.change-profile')}}</span>
                            </label>
                            <input id="upload-profile" @change="onFileSelected" accept="image/*" type="file"
                                   name="files"/>
                        </div>
                    </div>

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <div id="msg-code"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" maxlength="10"
                                   v-model="code" id="code-employee" required>
                            <label class="mdl-textfield__label" for="code-employee">{{ $t('add-employee.id')}}</label>
                            <span class="mdl-textfield__error">Employee id could be already registered</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-email"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="email" id="email-employee"
                                   v-model="email" autocomplete="email"
                                   pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" required>
                            <label class="mdl-textfield__label" for="email-employee">{{ $t('add-employee.email') }}</label>
                            <span class="mdl-textfield__error">Email could be already registered</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-name"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="name"
                                   id="name-employee" required>
                            <label class="mdl-textfield__label" for="name-employee">{{ $t('add-employee.name')}}</label>
                            <span class="mdl-textfield__error">Name is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-surname"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="surname"
                                   id="surname-employee" required>
                            <label class="mdl-textfield__label" for="surname-employee">{{ $t('add-employee.surname') }}</label>
                            <span class="mdl-textfield__error">Surname is required</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-mobile"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="mobile"
                                   maxlength="15" id="mobile-employee">
                            <label class="mdl-textfield__label" for="mobile-employee">{{
                                $t('add-employee.mobile')}}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                    </div>


                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">

                        <div id="msg-position"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="position"
                                   maxlength="15" id="position-employee">
                            <label class="mdl-textfield__label" for="position-employee">{{ $t('add-employee.position') }}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-workplace"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="workPlace"
                                   maxlength="20" id="workplace-employee">
                            <label class="mdl-textfield__label" for="workplace-employee">{{
                                $t('add-employee.workplace')}}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-department"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="department"
                                   maxlength="20" id="department-employee">
                            <label class="mdl-textfield__label" for="department-employee">{{
                                $t('add-employee.department')}}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-ssn" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <input class="mdl-textfield__input input-add-employee" type="text" v-model="ssn"
                                   maxlength="12" id="nss-employee">
                            <label class="mdl-textfield__label" for="nss-employee">{{ $t('add-employee.ssn')}}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                        <div id="msg-birthday"
                             class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                            <datepicker v-model="birthday"
                                        :language="pickerLang"
                                        placeholder=""
                                        clear-button
                                        name="birthday-employee">
                            </datepicker>
                            <label class="mdl-textfield__label">{{ $t('add-employee.birthday')}}</label>
                            <span class="mdl-textfield__error">Input is not right!</span>
                            <span class="mdl-textfield__success">Input OK!</span>
                        </div>

                    </div>

                    <div class="mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone manually-password">

                        <p class="title">{{ $t('add-employee.password-msg')}}</p>

                        <!-- Mini FAB button -->
                        <button @click="isManualPassSelected = !isManualPassSelected" class="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab">
                            <i v-if="isManualPassSelected" class="material-icons">clear</i>
                            <i v-else class="material-icons">add</i>
                        </button>

                        <div v-if="isManualPassSelected">

                            <div class="wrapper-password">
                                <div id="msg-password"
                                     class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                                    <input class="mdl-textfield__input input-add-employee" type="password" minlength="8"
                                           v-model="password" id="password-employee"
                                           pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                           title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                           required>
                                    <label class="mdl-textfield__label" for="password-employee">{{
                                        $t('shared.password')}}</label>
                                    <span class="mdl-textfield__error">Password must be alphanumerical and at least 8 characters</span>
                                </div>

                                <div id="msg-verify-password"
                                     class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                                    <input class="mdl-textfield__input input-add-employee" type="password" minlength="8"
                                           v-model="passwordVerify" id="verify-password-employee"
                                           pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                           title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                           required>
                                    <label class="mdl-textfield__label" for="verify-password-employee">{{ $t('shared.password-confirm')}}</label>
                                    <span class="mdl-textfield__error">The password and confirmation password do not match</span>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="mdl-grid">
                    <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                        <p id="msg-error" class="mdl-textfield__error">Error!</p>
                        <p id="msg-success" class="mdl-textfield__success"></p>
                    </div>
                    <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone wrapper-buttons">
                        <input type="hidden" id="id-employee">
                        <button type="submit" id="submit"
                                class="mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect">
                            {{ $t('add-employee.save')}}
                        </button>
                    </div>
                </div>
                <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
            </form>
        </div>
    </div>
</template>

<style scope lang="scss">
    .vdp-datepicker {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        display: block;
        font-size: 16px;
        font-family: "Helvetica", "Arial", sans-serif;
        margin: 0;
        padding: 4px 0;
        width: 100%;
        background: 0 0;
        text-align: left;
        color: inherit;
    }
</style>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import SectionTitle from "@/components/shared/SectionTitle"
    import VueElementLoading from 'vue-element-loading'
    import Datepicker from 'vuejs-datepicker'
    import {EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance";

    export default {
        name: "EmployeeNew",
        data() {
            return {
                isManualPassSelected: false,
                profile: EMPTY_IMAGE_PROFILE,
                code: '',
                name: '',
                surname: '',
                email: '',
                department: '',
                position: '',
                mobile: '',
                workPlace: '',
                birthday: '',
                password: '',
                passwordVerify: '',
                ssn: '',
                title: this.$i18n.t('add-employee.title')
            }
        },
        computed: {
            ...mapState('employee', ['requesting']),
            ...mapState({
                account : (state) => state.account.account,
                show : state => state.employee.requesting,
            }),
            ...mapGetters('config', ['pickerLang']),
            ...mapGetters('employee', ['getCountEmployees']),
        },
        methods: {
            ...mapActions('employee', ['addEmployee']),
            onFileSelected(event) {
                let file = event.target.files[0];
                let img = document.getElementById('profile-employee')
                if (file) {
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        img.src = e.target.result;
                    }
                    reader.readAsDataURL(file);
                }
            },
            async handleSubmit() {
                if (this.isManualPassSelected && this.password !== this.passwordVerify) {
                    this.$toast.error(this.$i18n.t('shared.password-mismatch'))
                    return
                }

                if (!this.account.canAddEmployees(this.getCountEmployees)) {
                    this.$toast.error(this.$i18n.t('add-employee.employee-max-msg'))
                    return
                }

                try {
                    let img = document.getElementById('profile-employee').src
                    const employee = {
                        profile: img === EMPTY_IMAGE_PROFILE ? '' : img,
                        code: this.code,
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        department: this.department,
                        position: this.position,
                        mobile: this.mobile,
                        workPlace: this.workPlace,
                        birthday: this.birthday,
                        ssn: this.ssn
                    }
                    if (this.isManualPassSelected) {
                        employee.password = this.password
                    }

                    await this.addEmployee(employee)
                    this.cleanProps()
                    this.$toast.success(this.$i18n.t('add-employee.successful'))
                } catch (error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
            cleanProps() {
                this.profile = EMPTY_IMAGE_PROFILE
                this.code = this.name = this.surname = this.email = this.department = this.position =
                    this.mobile = this.workPlace = this.birthday = this.password = this.ssn = this.passwordVerify = ""
            },
        },
        components: {
            SectionTitle,
            VueElementLoading,
            Datepicker
        }
    }
</script>
