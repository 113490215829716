import {webToCase} from "@/services/salesforce/CreateWeb2Case"

export default {
    namespaced: true,
    state: {
        requesting: false
    },
    actions: {
        supportCase({commit}, supportCase) {
            try {
                console.log(supportCase)
                commit('setRequesting', true)
                webToCase(supportCase)
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
    },
    mutations: {
        setRequesting(state, isRequesting) {
            state.requesting = isRequesting
        }
    }
}
