<template>
    <div class="mdl-layout__drawer" v-if="account">
        <header class="nav-drawer-header">

            <span class="mdl-chip mdl-chip--contact chip-company">
                <span class="mdl-chip__contact mdl-color--white mdl-color-text--black">
                    <span class="material-icons company-icon">business</span>
                </span>
                <span class="mdl-chip__text">{{ account.name }}</span>
            </span>

            <div class="wrapper-company-logo"><img class="company-logo" :src="account.logo" alt="Account image"></div>

            <span :class="[currentPage.includes('settings') ? activeClassSetting : '', 'none wrapper-setting']">
                <router-link class="setting" :to="{ name: 'Settings' }" exact>{{ $t('drawer.settings')}}</router-link>
            </span>

        </header>

        <nav id="nav" class="mdl-navigation">
            <span :class="[currentPage.includes('dashboard') ? activeClass : '', 'none']">
                <router-link class="mdl-navigation__link" :to="{ name: 'Dashboard' }" exact><span class="text">{{ $t('drawer.dashboard') }}</span></router-link>
            </span>
            <span :class="[currentPage.includes('employee/list') ? activeClass : '', 'none']">
                <router-link class="mdl-navigation__link" :to="{ name: 'EmployeeList' }" exact><span class="text">{{ $t('drawer.list-employees') }}</span></router-link>
            </span>
            <span :class="[currentPage.includes('employee/new') ? activeClass : '', 'none']">
                <router-link class="mdl-navigation__link" :to="{ name: 'EmployeeNew' }" exact><span class="text">{{ $t('drawer.new-employee') }}</span></router-link>
            </span>
            <span :class="[currentPage.includes('panel/record') ? activeClass : '', 'none']">
                <router-link class="mdl-navigation__link" :to="{ name: 'Record' }" exact><span class="text">{{ $t('drawer.attendances')}}</span></router-link>
            </span>
            <span :class="[currentPage.includes('panel/support') ? activeClass : '', 'none']">
                <router-link class="mdl-navigation__link" :to="{ name: 'Support' }" exact><span class="material-icons">contact_support</span><span
                        class="text"> {{ $t('drawer.support')}}</span></router-link>
            </span>
        </nav>
    </div>
</template>


<style scope lang="scss">
    .active-setting {
        border-bottom: 2px solid #019587;
    }

    .active {
        > .mdl-navigation__link > .text {
            border-bottom: 2px solid #019587;
        }
    }
</style>


<script>
    export default {
        name: "Drawer",
        data() {
            return {
                activeClass: 'active',
                activeClassSetting: 'active-setting',
            };
        },
        computed: {
            account() {
                return this.$store.state.account.account
            },
            currentPage() {
                return this.$route.path;
            }
        },
    }
</script>
