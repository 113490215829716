import {EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance"

const MAX_EMPLOYEES_FREEMIUM = 50

export class Account {

    constructor(id, name, logo, email, billing, website, createdAt, plan, lang) {

        this.id = id
        this.name = name
        this.logo = logo || EMPTY_IMAGE_PROFILE
        this.email = email
        this.billing = billing
        this.website = website
        this.createdAt = new Date(createdAt).toLocaleString()
        this.settings =  { lang: lang || 'es'}
        this.plan = plan
    }

    canAddEmployees(numberEmployees) {
        return !(this.plan === 'Freemium' && numberEmployees >= MAX_EMPLOYEES_FREEMIUM)
    }
}
