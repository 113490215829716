<template>
    <div class="container-login">
        <section class="wrapper-login">

            <div class="wrapper-logo">
                <img class="logo-app-login" src="../assets/images/logo-dembora-horiz.svg" alt="Logo Dembora">
            </div>

            <form @submit.prevent="handleSubmit">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                    <label class="mdl-textfield__label" for="email">{{ $t('login.email')}}</label>
                    <input class="mdl-textfield__input" type="email" name="email" v-model="email" id="email" :placeholder="emailPlaceholder" autocomplete="email" required>
                </div>

                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty">
                    <input class="mdl-textfield__input" :type="typeField" name="password" v-model.trim="password" :placeholder="passwordPlaceholder"  id="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" autocomplete="current-password" required>
                    <label class="mdl-textfield__label" for="password">{{ $t('login.password')}}</label>
                </div>

                <div class="checkbox">
                    <label class="mdl-switch mdl-js-switch mdl-js-ripple-effect" for="switch-1">
                        <input type="checkbox" @change="showPasswordClick($event)" v-model="checkedValue" id="switch-1" class="mdl-switch__input">
                        <span v-if="isActive" class="mdl-switch__label">{{ $t('login.hide-password') }}</span>
                        <span v-if="!isActive" class="mdl-switch__label">{{ $t('login.show-password') }}</span>
                    </label>
                </div>

                <div class="wrapper-submit">
                    <button type="submit" value="Log in" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
                        {{ $t('login.login')}}
                    </button>
                </div>
            </form>

            <div class="wrapper-recover-psw">
                <router-link class="link-recover" :to="{ name: 'ResetAccountPassword' }" exact>{{ $t('login.recover')}}</router-link>
            </div>

            <div class="wrapper-logo-herta">
                <img class="logo-herta-login" src="../assets/images/herta-icon.svg" alt="Logo Herta">
                <p class="texto-small">Powered by <a href="https://www.hertasecurity.com" target="_blank">Herta</a></p>
            </div>

            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>

        </section>
    </div>
</template>

<style scoped lang="scss">
</style>


<script>
    import {mapState, mapActions} from 'vuex'
    import VueElementLoading from 'vue-element-loading'

    export default {
        name: "Login",
        created() {
            this.emailPlaceholder = this.$i18n.t('login.email-placeholder')
            this.passwordPlaceholder = this.$i18n.t('login.password-placeholder')
        },
        data() {
            return {
                email: '',
                emailPlaceholder: '',
                password: '',
                passwordPlaceholder: '',
                typeField: 'password',
                currentState: false,
            }
        },
        computed: {
            ...mapState({
                show: state => state.auth.requesting
            }),

            isActive() {
            return this.currentState;
            },

            checkedValue: {
                get() {
                    return this.currentState
                },
                set(newValue) {
                    this.currentState = newValue;
                }
            }
        },
        methods: {
            ...mapActions('auth', ['login']),
            async handleSubmit() {
                try {
                    await this.login({
                        email: this.email,
                        password: this.password
                    })
                    this.$toast.success(this.$i18n.t('auth.login'))
                    await this.$router.push({name: 'Dashboard'})
                } catch(error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            },
            showPasswordClick(){
                if (this.typeField === 'password') {
                    this.typeField = 'text'
                } else {
                    this.typeField = 'password'
                }
            }
        },
        components: { VueElementLoading }
    }
</script>

