<template>
    <div id="app">
        <transition name="view">
            <router-view />
        </transition>
    </div>
</template>

<script>

    export default {
        name: 'App',
    }

</script>

<style>
    #nav a.router-link-exact-active {
        color: #000;
    }

    .view-enter-active, .view-enter-leave {
        transition: opacity 0.3s ease-in-out, transform 0.3s ease;
    }
    .view-enter-active {
        transition-delay: 0.1s;
    }
    .view-enter, .view-leave-to {
        opacity: 0;
    }
    .view-enter-to, .view-leave {
        opacity: 1;
    }

</style>
