import rest from "@/services/shared/RestApi";
import moment from 'moment'

class FetchRecord {

    request(accountId, filter) {
        return rest.get(this.getUrl(accountId, filter))
    }

    getUrl(accountId, filter) {
        let time = '?orderBy=createdAt&order=desc'
        if (filter.start !== '' && filter.finish !== '') {
            time += `&filters[0][field]=createdAt&filters[0][operator]=GT&filters[0][value]=${this.toStartDate(filter.start)}` +
                    `&filters[1][field]=createdAt&filters[1][operator]=LT&filters[1][value]=${this.toStartFinish(filter.finish)}`
        }
        return `/api/v1/account/${accountId}/employee/${filter.employeeId}/record${time}`
    }

    toStartDate(date) {
        return  moment(date).hour(0).minute(0).second(0).format()
    }

    toStartFinish(date) {
        return  moment(date).hour(23).minute(59).second(59).format()
    }
}

export default new FetchRecord()
