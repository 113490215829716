<template>
    <tr>
        <td class="mdl-data-table__cell--non-numeric">
            <div class="mask-record">
                <img class="profile-image-employee-record" :src="validatedImage" alt="Identification"/>
            </div>
        </td>
        <td class="mdl-data-table__cell--non-numeric">
            <span class="mdl-chip mdl-chip--contact">
                <span class="mdl-chip__contact mdl-color--blue mdl-color-text--white">{{ surname | firstLetter }}</span>
                <span class="mdl-chip__text table-name">{{ name }} {{ surname }}</span>
            </span>
        </td>
        <td class="mdl-data-table__cell--non-numeric"><span :class="[selectActionTypeClass]">{{ action }}</span></td>
        <td class="mdl-data-table__cell--non-numeric"><span class="action-date">{{ dateTime | moment('ll') }}</span></td>
        <td class="mdl-data-table__cell--non-numeric"><span class="action-time">{{ dateTime | moment('LT') }}</span></td>
        <td class="mdl-data-table__cell--non-numeric">{{ zone }}</td>
        <td class="mdl-data-table__cell--non-numeric">{{ place }}</td>
    </tr>
</template>

<style lang="scss">
    .in {
        background-color: #019587;
        padding: 5px 10px;
        border-radius: 22px;
        color: #fff;
    }

    .out {
        background-color: #EE5656;
        padding: 5px 10px;
        border-radius: 22px;
        color: #fff;
    }

    .pause {
        background-color: #D5D5D5;
        padding: 5px 10px;
        border-radius: 22px;
        color: #fff;
    }

    .resume {
        background-color: #F1C903;
        padding: 5px 10px;
        border-radius: 22px;
        color: #fff;
    }

</style>

<script>
    import {Attendance, EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance"
    import {GetZoneDateTime, StandardizeDateTime} from "@/services/shared/Utils"

    export default {
        name: "RecordActionTableRowItem",
        props: {
            id: {
                type: String
            },
            image: {
                type: String,
            },
            action: {
                type: String
            },
            name: {
                type: String,
            },
            surname: {
                type: String
            },
            date: {
                type: String
            },
            place: {
                type: String
            },
            score: {
                type: Number,
            },
            geo: {
                type: String
            }
        },
        computed: {
            validatedImage() {
                return this.image !== '' ? this.image : EMPTY_IMAGE_PROFILE
            },
            dateTime() {
                return StandardizeDateTime(this.date)
            },
            zone() {
                return GetZoneDateTime(this.date)
            },
            selectActionTypeClass() {
                if (this.action === Attendance.type.IN) return "action in"
                if (this.action === Attendance.type.OUT) return "action out"
                if (this.action === Attendance.type.PAUSE) return "action pause"
                if (this.action === Attendance.type.RESUME) return "action resume"
                return "action"
            }
        },
        filters: {
            firstLetter: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase()
            }
        }
    }
</script>
