import rest from "@/services/shared/RestApi"

class ResetPasswordEmployee {

    request(email) {
        return rest.post(this.getUrl(email))
    }

    getUrl(email) {
        return `/api/v1/reset/employee/${email}`
    }
}

export default new ResetPasswordEmployee();
