import {Account} from "@/domain/Account"

class Session {

    create(data, time) {
        localStorage.setItem('dembora.session', time)
        localStorage.setItem('dembora.id', data.id)
        localStorage.setItem('dembora.email', data.email)
        localStorage.setItem('dembora.billing', data.billing)
        localStorage.setItem('dembora.logo', data.logo)
        localStorage.setItem('dembora.website', data.website)
        localStorage.setItem('dembora.name', data.name)
        localStorage.setItem('dembora.createdAt', data.createdAt)
        localStorage.setItem('dembora.plan', data.plan)
        localStorage.setItem('dembora.lang', data.settings.lang)
    }

    get() {
        return {
            session: localStorage.getItem('dembora.session') || '',
            account: new Account(
                localStorage.getItem('dembora.id') || '',
                localStorage.getItem('dembora.name') || '',
                localStorage.getItem('dembora.logo') || '',
                localStorage.getItem('dembora.email') || '',
                localStorage.getItem('dembora.billing') || '',
                localStorage.getItem('dembora.website') || '',
                localStorage.getItem('dembora.createdAt') || '',
                localStorage.getItem('dembora.plan') || '',
                localStorage.getItem('dembora.lang') || ''
            )
        }
    }

    update(data) {
        localStorage.setItem('dembora.email', data.email)
        localStorage.setItem('dembora.billing', data.billing)
        localStorage.setItem('dembora.logo', data.logo)
        localStorage.setItem('dembora.website', data.website)
        localStorage.setItem('dembora.name', data.name)
        localStorage.setItem('dembora.plan', data.plan)
        localStorage.setItem('dembora.lang', data.settings.lang)
    }

    remove() {
        localStorage.removeItem('dembora.session')
        localStorage.removeItem('dembora.id')
        localStorage.removeItem('dembora.email')
        localStorage.removeItem('dembora.billing')
        localStorage.removeItem('dembora.logo')
        localStorage.removeItem('dembora.website')
        localStorage.removeItem('dembora.name')
        localStorage.removeItem('dembora.plan')
        localStorage.removeItem('dembora.createdAt')
        localStorage.removeItem('dembora.lang')
    }
}

export default new Session()
