<template>
    <div class="wrapper-users">
        <router-link :to="{
            name: 'EmployeeRecord',
            params: {
                employeeId: attendance.id}
            }">
            <span :class="[selectActionTypeClass]"></span>
            <div id="user" class="wrapper-image">
                <img :src="profile" class="photo-profile" alt="Employee photo profile">
                <p class="name">{{ attendance.name }} {{ attendance.surname }}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
    import {Attendance, EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance"

    export default {
        name: 'ProfileAttendance',
        props: {
            'attendance': {
                type: Object,
                required: true
            }
        },
        computed: {
            profile() {
                return this.attendance.profile !== '' ? this.attendance.profile : EMPTY_IMAGE_PROFILE
            },
            selectActionTypeClass() {
                return this.attendance.status === Attendance.type.IN || this.attendance.status === Attendance.type.RESUME  ? "badge-in" : "badge-out";
            }

        }
    }
</script>
