<template>
    <header class="mdl-layout__header">
        <div class="mdl-layout__header-row">

            <div aria-expanded="false" role="button" tabindex="0" class="mdl-layout__drawer-button"><i
                    class="material-icons"></i></div>

            <div class="mdl-layout-spacer"></div>

            <md-field id="selector-language">
                <md-select v-model="lang" @md-selected="handleChange()" name="selector language">
                    <md-option selected disabled>{{ $t('account-settings.language')}}</md-option>
                    <md-option value="en">{{ $t('account-settings.english')}}</md-option>
                    <md-option value="es">{{ $t('account-settings.spanish')}}</md-option>
                </md-select>
            </md-field>

            <div @click="logout" class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--icon" id="hdrbtn">
                <i class="material-icons">exit_to_app</i>
            </div>

        </div>
    </header>
</template>

<script>
    import {mapActions, mapState} from 'vuex'

    export default {
        name: "TopBar",
        created() {
            this.lang = this.locate
        },
        data() {
            return {
                lang: 'en'
            }
        },
        computed: {
            ...mapState('config', ['locate'])
        },
        methods: {
            ...mapActions('auth', ['logout']),
            ...mapActions('config', ['changeLang']),
            handleChange() {
                this.changeLang(this.lang)
            }
        }

    }

</script>
