import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from "@/i18n"
import Config from '@/services/config/Configuration'

const moment = require('moment')
moment.locale(Config.get().locate)
Vue.use(require('vue-moment'), {
    moment
})

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
Vue.use(VueToast, { position: 'top-right', duration: 3000, dismissible: true })

import '@/css/material.blue-teal.min.css'
import '@/css/sass/main.css'
import 'material-components-web'
import 'material-design-lite/material.min.js'


import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2, {
    customClass: {
        confirmButton: 'mdl-button mdl-button--colored mdl-js-button mdl-button--raised mdl-js-ripple-effect ml-2',
        cancelButton: 'mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect mr-2'
    },
    buttonsStyling: false
})

Vue.config.productionTip = false

new Vue({
    ...App,
    i18n,
    moment,
    router,
    store
}).$mount('#app')
