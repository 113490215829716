<template>
    <tr>
        <td class="mdl-data-table__cell--non-numeric">
            <div class="mask">
                <img class="profile-image-employee" :src="validatedProfile" alt="Employee profile"/>
            </div>
        </td>
        <td class="mdl-data-table__cell--non-numeric">{{ employee.code }}</td>
        <td class="mdl-data-table__cell--non-numeric">
            <span class="mdl-chip mdl-chip--contact">
                <span class="mdl-chip__contact mdl-color--blue mdl-color-text--white">{{ employee.surname | firstLetter }}</span>
                <span class="mdl-chip__text table-name">{{ employee.name }} {{ employee.surname }}</span>
            </span>
        </td>
        <td class="mdl-data-table__cell--non-numeric">{{ employee.email }}</td>
        <td class="mdl-data-table__cell--non-numeric">
            <router-link type="button" :to="{ name: 'EmployeeRecord', params: {employeeId: employee.id} }">
                <span class="material-icons records">fact_check</span>
            </router-link>
        </td>
        <td class="mdl-data-table__cell--non-numeric">
            <router-link type="button" :to="{ name: 'EmployeeDetails', params: {employeeId: employee.id} }">
                <span class="material-icons records">portrait</span>
            </router-link>
        </td>
    </tr>
</template>

<style scope lang="scss">
    .mdl-button.mdl-button--colored.mdl-button--raised.mdl-js-button.mdl-js-ripple-effect {
        color: #fff;
        &:hover {
            text-decoration: none;
        }
    }

    .mdl-data-table__cell--non-numeric {

        .material-icons.records {
            font-size: 33px;

            &:hover {
                color: #019587;
            }
        }
    }
    
</style>


<script>
    import {EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance"

    export default {
        name: "EmployeeTableRowItem",
        props: {
            employee : Object
        },
        computed: {
            validatedProfile() {
                return this.employee.profile !== '' ? this.employee.profile : EMPTY_IMAGE_PROFILE
            }
        },
        filters: {
            firstLetter: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase()
            }
        }
    }
</script>
