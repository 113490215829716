import rest from "@/services/shared/RestApi"

class AccountLogin {

    request(data) {
        return rest.post(this.getUrl(), data)
    }

    getUrl() {
        return `/api/v1/login/account`
    }
}

export default new AccountLogin();

