import moment from 'moment'

export function ValidateTime(time) {
    return !(time.includes('HH') ||time.includes('mm') || time === '')
}

export function StandardizeDateTime(time) {
    let zone = moment.parseZone(time).format('Z')
    if (zone === '+00:00') {
        return moment(time)
    }
    return moment.parseZone(time)
}

export function GetZoneDateTime(time) {
    let zone = moment.parseZone(time).format('Z')
    if (zone === '+00:00') {
        return moment(time).format('Z')
    }
    return moment.parseZone(time).format('Z')
}
