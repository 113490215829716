import Update from "@/services/account/UpdateAccount"
import DropOutAccount from "@/services/account/DropOutAccount"
import Session from '@/services/auth/Session'
import {Account} from "@/domain/Account";

export default {
    namespaced: true,
    state: {
        account: Session.get().account,
        requesting: false
    },
    actions: {
        async updateAccount({commit, state}, account) {
            try {
                commit('setRequesting', true)
                const {AccountUpdated} = await Update.request(state.account.id, account)
                commit('setAccount', new Account(
                    AccountUpdated.id,
                    AccountUpdated.name,
                    AccountUpdated.logo,
                    AccountUpdated.email,
                    AccountUpdated.billing,
                    AccountUpdated.website,
                    AccountUpdated.createdAt,
                    AccountUpdated.plan,
                    AccountUpdated.settings.lang)
                )
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
        async deleteAccount({commit, state, dispatch}) {
            try {
                commit('setRequesting', true)
                await DropOutAccount.request(state.account.id)
                dispatch("auth/logout", null, {root: true})
            } catch (error) {
                throw new Error(error.message)
            } finally {
                commit('setRequesting', false)
            }
        },
    },
    mutations: {
        setAccount(state, account) {
            Session.update(account)
            state.account = account
        },
        setRequesting(state, isRequesting) {
            state.requesting = isRequesting
        }
    }
}
