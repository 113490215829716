class Configuration {

    create(locate) {
        localStorage.setItem('dembora.locate', locate)
    }

    get() {
        return {
            locate: localStorage.getItem('dembora.locate') || this.default()
        }
    }

    update(locate) {
        localStorage.setItem('dembora.locate', locate)
    }

    default() {
        return navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage || "en"
    }
}

export default new Configuration()
