<template>
    <div id="wrapper-table">
        <table id="table-employees" class="mdl-data-table mdl-js-data-table mdl-shadow--2dp">
            <thead>
                <tr>
                    <th class="mdl-data-table__cell--non-numeric">{{ $t('list-employees.profile') }}</th>
                    <th class="mdl-data-table__cell--non-numeric">{{ $t('list-employees.id') }}</th>
                    <th class="mdl-data-table__cell--non-numeric">{{ $t('list-employees.full-name') }}</th>
                    <th class="mdl-data-table__cell--non-numeric">{{ $t('list-employees.email') }}</th>
                    <th class="mdl-data-table__cell--non-numeric">{{ $t('list-employees.records')}}</th>
                    <th class="mdl-data-table__cell--non-numeric">{{ $t('list-employees.details') }}</th>
                </tr>
            </thead>
            <tbody>
                <employee-table-row-item
                    v-for="employee in employees"
                    :key="employee.id"
                    :employee="employee"
                ></employee-table-row-item>
            </tbody>
        </table>
    </div>
</template>

<style scope lang="scss">
</style>

<script>
    import EmployeeTableRowItem from '@/components/employee/EmployeeTableRowItem'

    export default {
        name: "EmployeeTable",
        props: ['employees'],
        components: { EmployeeTableRowItem },
    }

</script>
